import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _63fa924e = () => interopDefault(import('..\\pages\\contact.vue' /* webpackChunkName: "pages/contact" */))
const _142beb4f = () => interopDefault(import('..\\pages\\faq.vue' /* webpackChunkName: "pages/faq" */))
const _6e67f475 = () => interopDefault(import('..\\pages\\about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _3365740a = () => interopDefault(import('..\\pages\\products.vue' /* webpackChunkName: "pages/products" */))
const _2c557458 = () => interopDefault(import('..\\pages\\service\\index.vue' /* webpackChunkName: "pages/service/index" */))
const _6a40a6cb = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _2fc2b6e8 = () => interopDefault(import('..\\pages\\service\\_slug.vue' /* webpackChunkName: "pages/service/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active-link',
  linkExactActiveClass: 'exact-active-link',
  scrollBehavior,

  routes: [{
    path: "/contact",
    component: _63fa924e,
    name: "contact___nl"
  }, {
    path: "/faq",
    component: _142beb4f,
    name: "faq___nl"
  }, {
    path: "/over-ons",
    component: _6e67f475,
    name: "about-us___nl"
  }, {
    path: "/producten",
    component: _3365740a,
    name: "products___nl"
  }, {
    path: "/service",
    component: _2c557458,
    name: "service___nl"
  }, {
    path: "/",
    component: _6a40a6cb,
    name: "index___nl"
  }, {
    path: "/service/:slug",
    component: _2fc2b6e8,
    name: "service-slug___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
